.container {
    position:relative;
    display: flex;
    width: 200px;
    height: 200px;
}


.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-width: 2px;
    border-radius: 8px;
    border-color: #C8C8C8;
    border-style: dashed;
    background-color: #FAFAFA;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}

.dropzoneNoPadding {
    padding: 0px;
}

.dropzone:active, .dropzoneDragging {
    border-color: #2196f3;
}

.dropzone.disabled {
    opacity: 0.6;
}

.dropzone p {
    text-align: center;
}

.loadingContainer {
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    display: flex;
    justify-content: center;
    align-items: center;
}