.container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100%;
	height: 100%;
}

.containerUploaded {
	border: 1px dashed #ccc;
}

.progressBar {
	width: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 100;
}

.progressBar.hidden {
	display: none;
}

.controlsContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;

	position: absolute;
	right: 10px;
    top: 10px;
	left: 10px;
    z-index: 2;
}
.controlsContainer .icon {
	margin-left: 10px;
}

.deleteIconContainer {
    position: absolute;
	right: 10px;
    top: 10px;
    border-radius: 100%;
    background-color: black;
    z-index: 10000;
    width: 25px;
    height: 25px;
	cursor: pointer;
}

